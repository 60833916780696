import { Application } from 'stimulus'
import Carousel from "../carousel_controller"
import Collapse from "../collapse_controller"
import CityAutocomplete from "../city_autocomplete_controller"
import Dropdown from "../dropdown_controller"
import FlyoutMenus from "../flyout_menus_controller"
import GooglePlaces from "../google_places_controller"
import LocationFilterTab from "../location_filter_tab_controller"
import Menu from "../menu_controller"
import Search from "../search_controller"
import Tab from "../tab_controller"
import SectorAutocomplete from "../sector_autocomplete_controller"
import ScrollReveal from "stimulus-scroll-reveal"
import Reveal from "stimulus-reveal-controller"
import Remote from "stimulus-remote-rails"

const stimulus = Application.start()
stimulus.debug = process.env.NODE_ENV === "development"

stimulus.register("carousel", Carousel)
stimulus.register("collapse", Collapse)
stimulus.register("city-autocomplete", CityAutocomplete)
stimulus.register("dropdown", Dropdown)
stimulus.register("flyout-menus", FlyoutMenus)
stimulus.register("google-places", GooglePlaces)
stimulus.register("location-filter-tab", LocationFilterTab)
stimulus.register("menu", Menu)
stimulus.register("search", Search)
stimulus.register("tab", Tab)
stimulus.register("sector-autocomplete", SectorAutocomplete)
stimulus.register("scroll-reveal", ScrollReveal)
stimulus.register("reveal", Reveal)
stimulus.register("remote", Remote)
