/* eslint no-console:0 */

// MODULES =====================================================================
import Rails from 'rails-ujs'

// Fonts
import "typeface-montserrat"

import ImageObjectFit from "~/scripts/utils/image-object-fit"
import ConstantHeight from "~/scripts/utils/constant-height"
import FlashMessage from "~/scripts/utils/flash-messages"
import executeRecaptcha from "~/scripts/utils/grecaptcha"
import Modale from "~/scripts/utils/modale"

// must be call first for real height map on mobile
/* eslint import/first:0 */
new ConstantHeight()

// Stimulus
import "~/scripts/controllers/home"

// CONFIGURATION ===============================================================
if (!window._rails_loaded) { Rails.start() }

window.FlashMessage = FlashMessage
window.Rails = Rails
window.executeRecaptcha = executeRecaptcha

document.addEventListener("DOMContentLoaded", () => {
  new ImageObjectFit()
  new Modale()
})
